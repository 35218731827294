var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "section",
        { staticClass: "leftPanelWrapper" },
        [
          _c("div", { staticClass: "leftPanel d-none d-lg-block" }, [
            _c(
              "div",
              {
                staticClass:
                  "brandSpace d-lg-flex align-items-center justify-content-between d-none"
              },
              [
                _c(
                  "div",
                  { staticClass: "logo text-left" },
                  [
                    _c(
                      "RouterLink",
                      {
                        attrs: { to: { name: "dashboard" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onMenuItemClick("dashboard")
                          }
                        }
                      },
                      [
                        _c(
                          "h5",
                          { staticClass: "d-flex align-items-center mb-0" },
                          [
                            _c("img", {
                              staticClass: "mr-2",
                              attrs: { src: "/images/stan-logo-text.svg" }
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { ref: "left-menu-desktop", staticClass: "menus" }, [
              _c(
                "ul",
                {
                  staticClass: "p-0 mb-0 w-100",
                  staticStyle: { "padding-top": "6px!important" }
                },
                [
                  _vm._l(_vm.filteredMenuItems, function(menu) {
                    return _c(
                      "li",
                      { key: menu.slug, staticClass: "menu-item d-block" },
                      [
                        menu.position === "top"
                          ? _c(
                              "RouterLink",
                              {
                                staticClass:
                                  "d-flex flex-wrap justify-content-start align-items-center h4 text-bold menu-item-link",
                                class: {
                                  open: _vm.activeRoute == menu.route,
                                  "hidden-menu-item":
                                    (menu.type === "app" ||
                                      menu.type === "admin") &&
                                    !_vm.isNavigationItemPinned(menu),
                                  "menu-item-new": menu.new
                                },
                                attrs: {
                                  to: menu.href
                                    ? menu.href
                                    : { name: menu.route },
                                  target: menu.href ? "_blank" : ""
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onMenuItemClick(menu.route)
                                  }
                                }
                              },
                              [
                                _c("InlineSvg", {
                                  staticClass: "mr-2 menu-item-icon",
                                  attrs: {
                                    id: menu.icon,
                                    src: "/images/icons/" + menu.icon + ".svg"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "col-auto pl-0 menu-item-title mt-0",
                                    class: menu.class
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.$t(menu.title)) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                    _c("InlineSvg", {
                                      staticClass: "new-icon",
                                      attrs: {
                                        src: "/images/icons/ic-double-star.svg"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  !_vm.isAffiliateOnlySubscription
                    ? _c(
                        "li",
                        { key: "more", staticClass: "menu-item d-block" },
                        [
                          _c(
                            "RouterLink",
                            {
                              staticClass:
                                "d-flex flex-wrap justify-content-start align-items-center h4 text-bold menu-item-link",
                              class: {
                                open: _vm.activeRoute === "apps"
                              },
                              attrs: { to: { name: "apps" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.onMenuItemClick("more")
                                }
                              }
                            },
                            [
                              _c("InlineSvg", {
                                staticClass: "mr-2 menu-item-icon",
                                attrs: {
                                  id: "more",
                                  src: "/images/icons/ic-plus.svg"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "col-auto pl-0 menu-item-title mt-0"
                                },
                                [_vm._v("More")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-100" }, [
                _c("ul", { staticClass: "p-0 mb-0 w-100 bottom-leftmenu" }, [
                  !_vm.isAffiliateOnlySubscription
                    ? _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c(
                            "RouterLink",
                            {
                              staticClass:
                                "d-flex justify-content-start align-items-center pad-remove h4 text-bold",
                              class: { open: _vm.activeRoute == "ask_stanley" },
                              attrs: { to: { name: "ask_stanley" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.onMenuItemClick("ask_stanley")
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "user-image-menu-spacer d-flex align-items-center justify-content-center mr-1"
                                },
                                [
                                  _c("InlineSvg", {
                                    attrs: {
                                      src: "/images/icons/ic-ask-stanley.svg"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "col pl-0" }, [
                                _vm._v(_vm._s(_vm.$t("Ask Stanley")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu-item " },
                    [
                      _c(
                        "RouterLink",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold",
                          class: {
                            open: _vm.activeRoute == "account_settings"
                          },
                          attrs: { to: { name: "account_settings" } },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onMenuItemClick("account_settings")
                            }
                          }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "mr-2 menu-item-icon",
                            attrs: { src: "/images/icons/ic-settings.svg" }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "col pl-0" }, [
                            _vm._v(_vm._s(_vm.$t("Settings")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu-item" },
                    [
                      _c("AccountMenu", {
                        on: {
                          show: function($event) {
                            return _vm.onMenuItemClick("accounts_menu")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("NavigationApps", { staticClass: "mobile-menu-wrapper" }),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-actions-menu" }, [
            _c(
              "ul",
              { staticClass: "p-0 mb-0 w-100" },
              [
                !_vm.isAffiliateOnlySubscription
                  ? _c("li", { key: "preview", staticClass: "menu-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "menu-item-link",
                          class: { open: _vm.showPreview },
                          on: { click: _vm.togglePreview }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: {
                              id: "preview",
                              src: "/images/icons/ic-show.svg"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "menu-item-title para-4" }, [
                            _vm._v(_vm._s(_vm.$t("Preview")))
                          ])
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.mobileActionMenuItems, function(menu) {
                  return _c(
                    "li",
                    { key: menu.slug, staticClass: "menu-item" },
                    [
                      _c(
                        "RouterLink",
                        {
                          staticClass: "menu-item-link",
                          class: {
                            open:
                              !_vm.showPreview &&
                              !_vm.showNavigation &&
                              _vm.activeRoute == menu.route
                          },
                          attrs: {
                            to: { name: menu.route },
                            "data-test-id": "action-menu-" + menu.title
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onMenuItemClick(menu.route)
                            }
                          }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: {
                              id: menu.icon,
                              src: "/images/icons/" + menu.icon + ".svg"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "menu-item-title para-4 text-center"
                            },
                            [_vm._v(_vm._s(menu.title))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("li", { key: "more", staticClass: "menu-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "menu-item-link",
                      class: { open: _vm.showNavigation },
                      on: { click: _vm.toggleMenu }
                    },
                    [
                      _c("InlineSvg", {
                        staticClass: "menu-item-icon",
                        attrs: { id: "more", src: "/images/icons/ic-plus.svg" }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "menu-item-title para-4" }, [
                        _vm._v("More")
                      ])
                    ],
                    1
                  )
                ])
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-preview-wrapper" },
            [
              _vm.getStoreType === "linksite" &&
              _vm.getStorePublishedPages &&
              _vm.getStorePublishedPages[0]
                ? _c("StorePreview", {
                    staticClass: "mobile-preview",
                    attrs: {
                      user: _vm.user,
                      blocks: _vm.getStorePublishedPages,
                      sections: _vm.sectionWithPages,
                      features: _vm.getStoreFeatures
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.getStoreType === "funnel" &&
              _vm.getStorePublishedPages &&
              _vm.getStorePublishedPages[_vm.previewCurrentPage]
                ? _c("StorePagePreview", {
                    staticClass: "mobile-preview",
                    attrs: {
                      user: _vm.user,
                      "page-data":
                        _vm.getStorePublishedPages[_vm.previewCurrentPage].data,
                      "funnel-data": _vm.getStore.data,
                      type:
                        _vm.getStorePublishedPages[_vm.previewCurrentPage].type,
                      "is-first": _vm.previewCurrentPage === 0,
                      "has-next": _vm.hasPreviewNext,
                      "has-previous": _vm.hasPreviewPrevious,
                      store: _vm.getStore,
                      "stripe-element-id-suffix": "-preview"
                    },
                    on: {
                      "update:pageData": function($event) {
                        return _vm.$set(
                          _vm.getStorePublishedPages[_vm.previewCurrentPage],
                          "data",
                          $event
                        )
                      },
                      "update:page-data": function($event) {
                        return _vm.$set(
                          _vm.getStorePublishedPages[_vm.previewCurrentPage],
                          "data",
                          $event
                        )
                      },
                      nextPage: _vm.nextPage,
                      prevPage: _vm.prevPage
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<section v-if="user" class="leftPanelWrapper">
		<div class="leftPanel d-none d-lg-block">
			<div class="brandSpace d-lg-flex align-items-center justify-content-between d-none">
				<div class="logo text-left">
					<RouterLink :to="{ name: 'dashboard' }" @click.native="onMenuItemClick('dashboard')">
						<h5 class="d-flex align-items-center mb-0">
							<img src="/images/stan-logo-text.svg" class="mr-2" />
						</h5>
					</RouterLink>
				</div>
			</div>
			<div class="menus" ref="left-menu-desktop">
				<ul class="p-0 mb-0 w-100" style="padding-top:6px!important">
					<li v-for="menu in filteredMenuItems" :key="menu.slug" class="menu-item d-block">
						<RouterLink
							v-if="menu.position === 'top'"
							:to="menu.href ? menu.href : { name: menu.route }"
							:class="{
								open: activeRoute == menu.route,
								'hidden-menu-item': (menu.type === 'app' || menu.type === 'admin') && !isNavigationItemPinned(menu),
								'menu-item-new': menu.new,
							}"
							class="d-flex flex-wrap justify-content-start align-items-center h4 text-bold menu-item-link"
							:target="menu.href ? '_blank' : ''"
							@click.native="onMenuItemClick(menu.route)"
						>
							<InlineSvg :id="menu.icon" :src="'/images/icons/' + menu.icon + '.svg'" class="mr-2 menu-item-icon" />
							<p class="col-auto pl-0 menu-item-title mt-0" :class="menu.class">
								{{ $t(menu.title) }}
								<InlineSvg src="/images/icons/ic-double-star.svg" class="new-icon" />
							</p>
						</RouterLink>
					</li>
					<li key="more" class="menu-item d-block" v-if="!isAffiliateOnlySubscription">
						<RouterLink
							:to="{ name: 'apps' }"
							:class="{
								open: activeRoute === 'apps',
							}"
							class="d-flex flex-wrap justify-content-start align-items-center h4 text-bold menu-item-link"
							@click.native="onMenuItemClick('more')"
						>
							<InlineSvg id="more" src="/images/icons/ic-plus.svg" class="mr-2 menu-item-icon" />
							<p class="col-auto pl-0 menu-item-title mt-0">More</p>
						</RouterLink>
					</li>
				</ul>

				<div class="w-100">
					<ul class="p-0 mb-0 w-100 bottom-leftmenu">
						<li class="menu-item" v-if="!isAffiliateOnlySubscription">
							<RouterLink
								:to="{ name: 'ask_stanley' }"
								:class="{ open: activeRoute == 'ask_stanley' }"
								class="d-flex justify-content-start align-items-center pad-remove h4 text-bold"
								@click.native="onMenuItemClick('ask_stanley')"
							>
								<span class="user-image-menu-spacer d-flex align-items-center justify-content-center mr-1">
									<InlineSvg src="/images/icons/ic-ask-stanley.svg" />
								</span>
								<p class="col pl-0">{{ $t('Ask Stanley') }}</p>
							</RouterLink>
						</li>
						<li class="menu-item ">
							<RouterLink
								:to="{ name: 'account_settings' }"
								:class="{ open: activeRoute == 'account_settings' }"
								class="d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold"
								@click.native="onMenuItemClick('account_settings')"
							>
								<InlineSvg src="/images/icons/ic-settings.svg" class="mr-2 menu-item-icon" />
								<p class="col pl-0">{{ $t('Settings') }}</p>
							</RouterLink>
						</li>
						<li class="menu-item">
							<AccountMenu @show="onMenuItemClick('accounts_menu')" />
						</li>
					</ul>
				</div>
			</div>
		</div>
		<NavigationApps class="mobile-menu-wrapper" />
		<div class="mobile-actions-menu">
			<ul class="p-0 mb-0 w-100">
				<li key="preview" class="menu-item" v-if="!isAffiliateOnlySubscription">
					<a class="menu-item-link" :class="{ open: showPreview }" @click="togglePreview">
						<InlineSvg id="preview" src="/images/icons/ic-show.svg" class="menu-item-icon" />
						<p class="menu-item-title para-4">{{ $t('Preview') }}</p>
					</a>
				</li>
				<li v-for="menu in mobileActionMenuItems" :key="menu.slug" class="menu-item">
					<RouterLink
						:to="{ name: menu.route }"
						:class="{ open: !showPreview && !showNavigation && activeRoute == menu.route }"
						:data-test-id="`action-menu-${menu.title}`"
						class="menu-item-link"
						@click.native="onMenuItemClick(menu.route)"
					>
						<InlineSvg :id="menu.icon" :src="'/images/icons/' + menu.icon + '.svg'" class="menu-item-icon" />
						<p class="menu-item-title para-4 text-center">{{ menu.title }}</p>
					</RouterLink>
				</li>
				<li key="more" class="menu-item">
					<a class="menu-item-link" :class="{ open: showNavigation }" @click="toggleMenu">
						<InlineSvg id="more" src="/images/icons/ic-plus.svg" class="menu-item-icon" />
						<p class="menu-item-title para-4">More</p>
					</a>
				</li>
			</ul>
		</div>
		<div class="mobile-preview-wrapper">
			<StorePreview
				v-if="getStoreType === 'linksite' && getStorePublishedPages && getStorePublishedPages[0]"
				:user="user"
				:blocks="getStorePublishedPages"
				:sections="sectionWithPages"
				class="mobile-preview"
				:features="getStoreFeatures"
			/>
			<StorePagePreview
				v-if="getStoreType === 'funnel' && getStorePublishedPages && getStorePublishedPages[previewCurrentPage]"
				:user="user"
				:page-data.sync="getStorePublishedPages[previewCurrentPage].data"
				:funnel-data="getStore.data"
				:type="getStorePublishedPages[previewCurrentPage].type"
				:is-first="previewCurrentPage === 0"
				class="mobile-preview"
				:has-next="hasPreviewNext"
				:has-previous="hasPreviewPrevious"
				:store="getStore"
				:stripe-element-id-suffix="'-preview'"
				@nextPage="nextPage"
				@prevPage="prevPage"
			/>
		</div>
	</section>
</template>
<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex'
	import helpers from '~/global_helper/helpers.js'
	import StorePreview from '~/components/preview/StorePreview'
	import StorePagePreview from '~/components/preview/StorePagePreview'
	import NavigationApps from './NavigationApps'
	import AccountMenu from './AccountMenu'

	export default {
		name: 'LeftMenu',
		components: { StorePreview, StorePagePreview, NavigationApps, AccountMenu },
		props: ['hasHeaderNoti'],
		data() {
			return {
				activeRoute: 'dashboard',
				menuItems: [],
				previewCurrentPage: 0,
				hasPreviewPrevious: false,
				hasPreviewNext: false,
			}
		},
		created() {
			if (this.user.subscription_tier === this.$constants.TIER.AFFILIATE) {
				this.menuItems = this.$constants.AFFILIATE_MENUITEMS
				this.setActiveRoute()
				this.reloadStore()
			} else {
				this.menuItems = this.$constants.MENUITEMS
				this.setActiveRoute()
				this.reloadStore()
			}
		},
		computed: {
			...mapGetters('Auth', ['user', 'accounts']),
			...mapGetters('Global', ['showNavigation', 'showPreview']),
			...mapGetters('ResellRights', ['resellProducts']),
			...mapGetters('Stores', [
				'getStorePublishedPages',
				'getStoreType',
				'getStore',
				'getStoreId',
				'getStoreFeatures',
				'getStoreSections',
				'getStorePagesWithSections',
			]),
			isFunnelAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('funnels') !== -1
			},
			allowedOptionalMenus() {
				const optional = []
				for (let i = 0; i < Object.keys(this.user.tags).length; i++) {
					if (this.user.tags?.inbox) {
						optional.push('inbox')
					}
				}
				return optional
			},
			mobileActionMenuItems() {
				return this.menuItems.filter(menu => menu.mobileAction).sort((a, b) => (a.mobileOrder || 999) - (b.mobileOrder || 999))
			},
			filteredMenuItems() {
				return this.menuItems.filter(menu => {
					let showMenu = false
					const userRoles = this.user.list_of_roles
					const menuRoles = Object.values(menu.roles)
					// Restrict menu by roles
					showMenu = userRoles.some(v => menuRoles.indexOf(v) !== -1)
					// Restrict menu to enrolled users
					if (menu.optional && !this.allowedOptionalMenus.includes(menu.route)) {
						showMenu = false
					}

					const availableModules = this.user.subscription_tier_allowed_modules

					if (menu.moduleName && availableModules.indexOf(menu.moduleName) !== -1) {
						showMenu = true
					} else if (availableModules.indexOf('general-modules') === -1) {
						showMenu = false
					}

					// Do not show funnels for basic users
					if (menu.route === 'funnels' && this.isFunnelAllowed) {
						showMenu = true
					}

					if (menu.route === 'affiliate' && this.resellProducts.length) {
						showMenu = true
					}

					if (menu.experiments && menu.experiments.length > 0) {
						showMenu = false
						for (let i = 0, len = menu.experiments.length; i < len; i++) {
							if (this.user.experiments[menu.experiments[i]] === 1) {
								showMenu = true
							}
						}
					}

					if (this.$route.path && this.$route.path.includes(menu.route)) {
						showMenu = true
					}

					return showMenu
				})
			},
			filterAppItems() {
				return this.menuItems.filter(menu => menu.type === 'app')
			},
			filterAdminAppItems() {
				return this.menuItems.filter(menu => {
					let showMenu = false
					if (menu.type === 'admin') {
						showMenu = true
					}

					const userRoles = this.user.list_of_roles
					const menuRoles = Object.values(menu.roles)
					// Restrict menu by roles
					showMenu = showMenu && userRoles.some(v => menuRoles.indexOf(v) !== -1)

					return showMenu
				})
			},
			filterAcconutAppItems() {
				return this.menuItems.filter(menu => menu.type === 'menu' && menu.position === 'bottom')
			},
			isAffiliateOnlySubscription() {
				return this.user.subscription_tier === this.$constants.TIER.AFFILIATE
			},
			storePagesWithSections: {
				get() {
					return this.getSortedPages(this.getStorePagesWithSections)
				},
				set(pages_) {
					this.setStorePagesWithSections(pages_)
				},
			},
			sectionWithPages() {
				// Section Published Status = 1
				const sections = [...this.storePagesWithSections.filter(p => p.section_id !== undefined)]

				if (sections.length === 0) {
					// If no section found, Add one at the top and have all pages added to that section.
					sections.push({
						section_id: -1,
						store_id: this.getStoreId,
						status: 1,
						order: 0,
						data: {},
					})
					// storePagesWithSections should only have pages as it did not had the sections. So lets add all pages with status == 2
					sections[0].pages = [...this.storePagesWithSections.filter(p => p.status === 2)]
				} else {
					// If no section at the top of the page, then add Psuedo section, and add all non-section based pages to it
					if (sections[0].order !== 1) {
						sections.unshift({
							section_id: -1,
							store_id: this.getStoreId,
							status: 1,
							order: 0,
							data: {},
						})
					}
					const totalSections = sections.length
					sections.forEach((section, idx) => {
						const orderStart = section.order
						const orderEnd = idx + 1 < totalSections ? sections[idx + 1].order : 9999
						section.pages = [...this.storePagesWithSections.filter(p => p.status === 2 && p.order >= orderStart && p.order < orderEnd)]
					})
				}

				return sections
			},
		},
		watch: {
			'$route.name': {
				immediate: true,
				handler() {
					this.setActiveRoute()
					this.reloadStore()
				},
			},
			showPreview() {
				$('.mobile-preview-wrapper .site__prev__masking').animate({ scrollTop: 0 }, 0)
			},
			showNavigation() {
				$('.mobile-apps-wrapper').animate({ scrollTop: 0 }, 0)
			},
			getStore: {
				immediate: true,
				handler() {
					this.previewCurrentPage = 0
					this.setNavigationButtonVisibility()
				},
			},
			previewCurrentPage: {
				immediate: true,
				handler() {
					this.setNavigationButtonVisibility()
				},
			},
		},
		methods: {
			...mapMutations('Global', ['setShowNavigation', 'setShowPreview']),
			...mapActions('Stores', ['fetchStore', 'setStorePagesWithSections']),
			reloadStore() {
				if (!this.getStoreLoading) {
					let storeId = 'self'
					let storeType = 'linksite'
					if (this.$route.name.startsWith('funnel') && this.$route.params.storeId) {
						storeId = this.$route.params.storeId
						storeType = 'funnels'
					}
					if (!this.getStoreType || this.getStoreType !== storeType || (this.getStoreType === 'funnels' && storeId !== this.getStoreId)) {
						this.fetchStore({ storeId, storeType })
					}
				}
			},
			setActiveRoute() {
				if (this.$route.name === 'apps') {
					this.activeRoute = this.$route.name
					return
				}
				for (let i = 0; i < this.menuItems.length; i++) {
					const menuItem = this.menuItems[i]
					const baseRouteName = this.$route?.matched[0].name ?? ''
					if (baseRouteName === menuItem.routeName) {
						this.activeRoute = menuItem.route
						return
					}
				}
			},
			async logout() {
				$('body').removeClass('menu-active')
				clearInterval(this.t)
				await this.$store.dispatch('Auth/logout')
				this.$router.push({ name: 'login_page' }).catch(() => {})
			},

			toggleMenu() {
				const isPreviewVisible = this.showPreview
				this.setShowPreview(false)
				setTimeout(() => this.setShowNavigation(!this.showNavigation), isPreviewVisible ? 250 : 0)
			},

			openMenu() {
				const isPreviewVisible = this.showPreview
				this.setShowPreview(false)
				setTimeout(() => this.setShowNavigation(true), isPreviewVisible ? 250 : 0)
			},

			closeMenu() {
				this.setShowNavigation(false)
			},
			onMenuItemClick(menuOption) {
				this.$stanAnalytics('menu-option-clicked', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: { menu_option: menuOption },
				})
				this.closeMenu()
				this.setShowPreview(false)
			},
			togglePreview() {
				const isNavVisible = this.showNavigation
				this.closeMenu()
				setTimeout(() => this.setShowPreview(!this.showPreview), isNavVisible ? 250 : 0)
			},
			prevPage() {
				this.previewCurrentPage -= 1
			},
			nextPage() {
				this.previewCurrentPage += 1
			},
			setNavigationButtonVisibility() {
				if (
					this.getStorePublishedPages &&
					this.getStorePublishedPages.length &&
					this.getStorePublishedPages.length > 0 &&
					this.previewCurrentPage > 0
				) {
					this.hasPreviewPrevious = true
				} else {
					this.hasPreviewPrevious = false
				}

				if (
					this.getStorePublishedPages &&
					this.getStorePublishedPages.length &&
					this.previewCurrentPage < this.getStorePublishedPages.length - 1
				) {
					this.hasPreviewNext = true
				} else {
					this.hasPreviewNext = false
				}
			},
			isNavigationItemPinned(menu) {
				if (menu.new) return true
				return this.user.data.pinned_navigation && this.user.data.pinned_navigation.map(e => e.id).indexOf(menu.id) !== -1
			},
			getSortedPages(pages) {
				let order = 1

				return pages
					.map(page => {
						if (page.section_id !== undefined) {
							// This means its section
							page.order = order
							order++
							return page
						}

						let pageDefault = this.$constants.PAGE_DEFAULTS.default
						if (page.type in this.$constants.PAGE_DEFAULTS) {
							pageDefault = this.$constants.PAGE_DEFAULTS[page.type]
						}
						page.icon_page_list = pageDefault.icon

						if (typeof page.bottom_title === 'undefined' || page.bottom_title === '') {
							page.bottom_title = pageDefault.bottom_title
						}
						page.page_list_title = page.data.product.title || page.data.bottom_title
						if (this.isStoreLinkSite && page.data.location === 'store') {
							const title = helpers.getStorePageListTitle(page)
							if (title) {
								page.page_list_title = title
							}
						}
						page.order = order
						order++
						return page
					})
					.sort((a, b) => a.order - b.order)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.leftPanelWrapper {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;

		.leftPanel {
			position: fixed;
			left: 0px;
			top: 100%;
			width: 100%;
			height: 100vh;
			box-shadow: 0 6px 16px rgba(71, 25, 117, 0.09);
			transition: all ease 0.5s;
			background: var(--stan-white);
			display: flex;
			flex-direction: column;
			padding-bottom: 60px;
			will-change: top, left, position, scroll-position;
			-webkit-transform: translateZ(0);
			padding: 0;

			@media (max-width: 1370px) {
				@media (min-width: 992px) {
					padding: 15px;
				}
			}

			@media (min-width: 992px) {
				background: var(--stan-primary-light-color);
				width: 220px;
				max-width: 220px;
				max-height: unset;
				box-shadow: none;
				left: 0;
				top: 0;
				transition: none;
				padding: 15px 0;
				padding-right: 25px;
			}

			.toggler {
				display: none;
			}

			.brandSpace {
				padding: 15px;
				margin-bottom: 10px;
				padding-left: 22px;
				a {
					h5 {
						color: var(--stan-primary-dark-color);
						font-weight: bold;
					}
				}
			}

			.menus {
				flex-grow: 1;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				flex-wrap: wrap;
				align-content: space-between;
				overflow-y: scroll;
				padding: 10px 0;
				background-color: var(--stan-white);

				-ms-overflow-style: none; /* for Internet Explorer, Edge */
				scrollbar-width: none; /* for Firefox */

				@media (min-width: 992px) {
					height: calc(100% - 79px - 0px);
					padding: 0;
					background-color: transparent;
					border-radius: 0;
					box-shadow: none;
				}
				ul {
					display: flex;
					flex-direction: column;
					gap: 15px;
					@media (min-width: 992px) {
						display: block;
					}
					li {
						display: block;
						box-shadow: var(--stan-box-shadow);
						border-radius: 10px;
						overflow: hidden;
						@media (min-width: 992px) {
							box-shadow: none;
							border-radius: 0;
						}
						.menu-item-icon::v-deep {
							width: 24px;
							height: 24px;
							path:not(.no-fill),
							rect:not(.no-fill) {
								fill: var(--stan-primary-dark-color);
							}
						}
						a {
							background: var(--stan-white);
							color: var(--stan-text-dark-color);
							display: flex;
							padding: 12px 0 14px;
							text-decoration: none;
							padding-left: 25px !important;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;
							@media (min-width: 992px) {
								border-radius: 0 30px 30px 0;
								background: none;
							}
							&.pad-remove {
								padding-left: 20px !important;
							}

							&:hover {
								color: var(--stan-primary-primary-color);
								.menu-item-icon::v-deep {
									path:not(.no-fill),
									rect:not(.no-fill) {
										fill: var(--stan-primary-primary-color);
									}
								}
							}
							&.try-creator-pro {
								background: #e2dfff;
							}
							&.open {
								background: var(--stan-primary-primary-color);
								color: var(--stan-white);
								font-weight: 600;
								&:after {
									content: '';
									height: 100%;
									width: 7px;
									background: var(--stan-primary-primary-color);
									position: absolute;
									left: 0;
									top: 0;
								}
								i {
									color: var(--stan-primary-primary-color);
								}
								&:after {
									display: none;
								}

								.menu-item-icon::v-deep {
									path:not(.no-fill),
									rect:not(.no-fill) {
										fill: var(--stan-white);
									}
								}
							}
							.rotateI {
								transform: rotate(90deg);
								transition: all ease 0.3s;
							}
							p {
								margin-bottom: 0;
							}
						}
					}
				}
				&::-webkit-scrollbar {
					display: none;
				}
				.bottom-leftmenu {
					li {
						a {
							span {
								width: 30px;
								height: 30px;
								min-width: 30px;
								.roundedImg {
									width: 30px;
									height: 30px;
									min-width: 30px;
								}
							}
						}
					}
				}
			}
		}
	}

	.mobile-actions-menu {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #f3f0ff;
		z-index: 1;
		@media (min-width: 992px) {
			display: none;
		}
		ul {
			display: flex;
			list-style: none;
			li {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
				.menu-item-link {
					padding: 15px 5px;
					display: flex;
					flex-direction: column;
					align-items: center;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					color: var(--stan-text-dark-color);
					font-size: 0.9rem;
					@media (min-width: 400px) {
						font-size: 1rem;
					}
					&:hover {
						cursor: pointer;
					}
					.menu-item-icon::v-deep {
						width: 24px;
						height: 24px;
						@media (min-width: 768px) {
							width: 28px;
							height: 28px;
							margin-bottom: 2px;

							path,
							rect {
								fill: var(--stan-gray-dark-color);
							}
						}
						path,
						rect {
							fill: var(--stan-text-dark-color);
						}
					}
					&:hover,
					&.open {
						position: relative;
						color: var(--stan-primary-primary-color);
						font-weight: 500;
						&:after {
							content: '';
							height: 100%;
							width: 7px;
							background: var(--stan-primary-primary-color);
							position: absolute;
							left: 0;
							top: 0;
						}
						i {
							color: var(--stan-primary-primary-color);
						}
						&:after {
							display: none;
						}

						.menu-item-icon::v-deep {
							path:not(.no-fill),
							rect:not(.no-fill) {
								fill: var(--stan-primary-primary-color);
							}
						}
					}
				}
			}
		}
	}
	.menu-active .leftPanel {
		top: 0;
		position: relative;
		.menus {
			padding: 15px;
			padding-bottom: 100px;
		}
		@media (min-width: 992px) {
			top: 0;
			bottom: 0;
			left: 0;
			position: fixed;
			height: 100vh;
			padding: 0;
			.icon-menu {
				display: none;
			}
			.icon-close {
				display: block;
			}
		}
	}

	.menu-active .leftPanelWrapper,
	.preview-active .leftPanelWrapper {
		position: relative;
		height: auto;
		min-height: 100%;
		min-width: 100%;
		@media (min-width: 992px) {
			position: fixed;
			height: 100vh;
			min-width: none;
		}
	}

	.mobile-preview-wrapper {
		transition: all ease 0.5s;
		position: fixed;
		top: 100%;
		left: 0;
		width: 100%;
		padding-bottom: 60px;
		align-items: center;
		background: var(--stan-blue-8);
		will-change: top, left, position, scroll-position;
		-webkit-transform: translateZ(0);
	}

	.preview-active .mobile-preview-wrapper {
		position: relative;
		top: 10px;
		@media (min-width: 992px) {
			position: fixed;
			top: 100%;
			display: none;
		}
	}
	.mobile-menu-wrapper {
		transition: all ease 0.5s;
		position: fixed;
		top: 100%;
		left: 0;
		width: 100%;
		padding-bottom: 60px;
		align-items: center;
		background: var(--stan-blue-8);
		will-change: top, left, position, scroll-position;
		-webkit-transform: translateZ(0);
	}

	.menu-active .mobile-menu-wrapper {
		position: relative;
		top: 0;
		@media (min-width: 992px) {
			position: fixed;
			top: 100%;
			display: none;
		}
	}

	.mobileClosePanelSpace {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0;
		background: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(4px);
		z-index: 14;
	}
	.user-image-menu-spacer {
		border-radius: 100%;
		overflow: hidden;
		box-sizing: content-box;
	}
	.menu-item-title {
		margin-top: 6px;
		.new-icon {
			display: none;
		}
	}
	.mobile-apps-wrapper {
		width: 100%;
		padding: 25px;
		height: 100vh;
		padding-bottom: 100px;
		overflow-y: scroll;
		.mobile-apps-header {
			margin-bottom: 20px;
			margin-top: 40px;
		}

		.mobile-apps {
			display: flex;
			flex-direction: row;
			gap: 20px;
			flex-wrap: wrap;

			.app {
				margin-top: 10px;
				.app-icon {
					padding: 15px;
					border-radius: 15px;
					background: var(--stan-primary-light-color);
					box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
				}
				.app-text {
					width: 100%;
					text-align: center;
					padding-top: 18px;
				}
			}
		}
	}

	.mobile-admin-apps {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px;
		gap: 15px;
		.app {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			background: #ffffff;
			box-shadow: 0px 15px 50px rgba(0, 16, 109, 0.07);
			border-radius: 10px;
			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 10px;
				gap: 13px;
				width: 100%;

				.app-icon {
					padding: 8px;
					display: flex;
					width: 36px;
					height: 36px;
					background: var(--stan-primary-light-color);
					box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
					border-radius: 8px;
					svg {
						width: 20px;
						height: 20px;
						padding: 0px;
					}
				}
			}
		}
	}

	#ic-apps::v-deep {
		path {
			fill: var(--stan-primary-primary-color) !important;
			stroke: var(--stan-primary-primary-color) !important;
		}
		rect {
			fill: var(--stan-primary-primary-color) !important;
			stroke: var(--stan-primary-primary-color) !important;
		}
	}
	.open {
		#ic-apps::v-deep {
			path {
				fill: #fff !important;
				stroke: #fff !important;
			}
			rect {
				fill: #fff !important;
				stroke: #fff !important;
			}
		}
	}
	.hidden-menu-item {
		transform: translateX(-200px) scale(0.2);
		opacity: 0;
		height: 0px;
		padding: 0px !important;
		transition: 0.2s ease-in-out;
		&.open {
			height: 56px;
			opacity: 1;
			transform: translateX(0) scale(1);
			padding: 12px 0 14px !important;
		}
	}
	.menu-item-new {
		.menu-item-title {
			.new-icon {
				display: block;
				height: 15px;
				width: 15px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		&.open {
			.menu-item-title {
				.new-icon::v-deep {
					path:not(.no-fill),
					rect:not(.no-fill) {
						fill: var(--stan-white);
					}
				}
			}
		}
	}
</style>
